.react-tel-input {
    position: relative;
    height: 46px;
    width: 100%;
}
.react-tel-input .form-control {
    height: 100%;
    width: calc(100% - 108px);
    margin-left: 108px;
    background-color: #2c2d35;
    color: #fcfcfc;
    border-radius: 4px;
    padding: 12px 16px;
}

.react-tel-input > input {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
}

input::placeholder {
    color: #c7c7c7;
    font-weight: 400;
    font-size: 16px;
    line-height: 22.4px;
    letter-spacing: 0.15px;
}

.react-tel-input .special-label {
    display: none;
}

.react-tel-input .flag-dropdown {
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    top: 0;
    left: 0;
    width: 100px;
    background-color: #2c2d35;
    border-radius: 4px;
}

.react-tel-input .selected-flag {
    position: relative;
    width: 52px;
    height: 80%;
    padding: 0 0 0 12px;
}

.react-tel-input .country-list {
    outline: none;
    z-index: 1;
    list-style: none;
    position: absolute;
    top: 40px;
    padding: 0;
    margin: 10px 0 10px -1px;
    box-shadow: 1px 2px 18px rgba(0, 0, 0, 0.25);
    background-color: #2c2d35;
    width: 300px;
    max-height: 220px;
    overflow-y: scroll;
    border-radius: 4px;
}

.react-tel-input .country-list .flag {
    display: inline-block;
    position: absolute;
    left: 13px;
    top: 8px;
}
.react-tel-input .country-list .divider {
    padding-bottom: 5px;
    margin-bottom: 5px;
    border-bottom: 1px solid #ccc;
}
.react-tel-input .country-list .country {
    position: relative;
    padding: 12px 9px 13px 46px;
}

.react-tel-input .country-list .country.highlight {
    background-color: #2c2d35;
}

.react-tel-input .country-list .country:hover {
    background-color: #2c2d35;
}

.react-tel-input .country-list .country .dial-code {
    color: #fcfcfc;
}

.react-tel-input .country-list .country .country-name {
    color: #fcfcfc;
}
